var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("header", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "dialog-body" }, [
          _c("span", { staticClass: "message" }, [_vm._v(_vm._s(_vm.content))]),
          _c(
            "div",
            [
              _c("cropper", {
                staticClass: "cropper",
                attrs: {
                  src: _vm.image,
                  "default-size": _vm.defaultSize,
                  "output-size": 0.5,
                  imageRestriction: "fit-area",
                  "stencil-props": {
                    aspectRatio: _vm.ratioX / _vm.ratioY,
                  },
                },
                on: { change: _vm.onChange },
              }),
            ],
            1
          ),
        ]),
        _c("footer", [
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button cancel",
                on: { click: _vm.onNegativeClick },
              },
              [_vm._v(_vm._s(_vm.negative))]
            ),
            _c(
              "button",
              {
                staticClass: "button save",
                on: { click: _vm.onPositiveClick },
              },
              [_vm._v(_vm._s(_vm.positive))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }