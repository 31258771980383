
import MaterialInputItem from "@/components/molecules/lists/MaterialInputItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import {
  LayoutEnum,
  MaterialAttribute,
  MaterialFolder,
  MaterialInputTemplate,
  MaterialItem,
  MaterialItemEnum,
  NovelMaterial,
} from "@/lib/models";
import {
  Dialog,
  initLandscapeItem,
  initMultilineItem,
  initPortrateItem,
  initSinglelineItem,
  initSquareItem,
  isBilling,
} from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import Vue from "vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import MaterialPreviewDialog, { MaterialPreviewDialogProps } from "@/components/ui/MaterialPreviewDialog.vue";
import { NavigationGuardNext } from "vue-router";
import _ from "lodash";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

interface Data {
  folderId?: string;
  currentAttribute: MaterialAttribute | null;
  currentTemplate: string | null;
  currentLayout: LayoutEnum | null;
  currentNovelMaterial: NovelMaterial | null;
  currentBasicItems: MaterialItem[];
  currentCustomItems: MaterialItem[];
  checkedItems: MaterialItem[];
  isProgress: boolean;
  unbindShortcutKeys: (() => void) | null;
}

interface Methods {
  initialize: () => void;
  setTemplate: (template: MaterialInputTemplate) => void;
  setBasicItems: (layout: string) => void;
  addCustomItem: (type: MaterialItemEnum) => void;
  updateCheckedItems: (item: MaterialItem) => void;
  updateFolder: (folder: MaterialFolder, materialId: string, includes: boolean) => MaterialFolder;
  createNovelMaterial: () => void;
  updateNovelMaterial: () => void;
  updateMaterialFolder: (isDelete: boolean) => void;
  deleteNovelMaterial: () => void;
  onSelectTemplate: (event: Event) => void;
  onSelectLayout: (event: Event) => void;
  onUpdateItem: (item: MaterialItem) => void;
  onReorder: (items: MaterialItem[]) => void;
  onAddTextClick: () => void;
  onAddSquareClick: () => void;
  onAddPortraitClick: () => void;
  onAddLandscapeClick: () => void;
  onSaveButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onPreviewButtonClick: () => void;
  onCancelClick: () => void;
  showErrorDialog: (message: string) => void;
  showLeaveConfirmDialog: (next: NavigationGuardNext<Vue>) => void;
  beforeUnload: (event: BeforeUnloadEvent) => void;
  initializeOriginalData: () => void;
}

interface Computed {
  attributes: MaterialAttribute[];
  templates: MaterialInputTemplate[];
  deleteCount: string;
  isCreate: boolean;
  isActiveSaveButton: boolean;
  isActiveDeleteButton: boolean;
  isFormChanged: boolean;
}

interface Props {
  novelId: string;
  materialAttributeId: string;
  materialId: string;
}

type TOriginalData = {
  currentAttribute: MaterialAttribute | null;
  currentTemplate: string | null;
  currentLayout: LayoutEnum | null;
  currentBasicItems: MaterialItem[];
  currentCustomItems: MaterialItem[];
};

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },

  components: { MaterialInputItem, SortableList, MaskedLoading },

  data() {
    return {
      currentAttribute: null,
      currentTemplate: null,
      currentLayout: null,
      currentNovelMaterial: null,
      currentBasicItems: [],
      currentCustomItems: [],
      checkedItems: [],
      isProgress: false,
      originalData: {
        currentAttribute: null,
        currentTemplate: null,
        currentLayout: null,
        currentBasicItems: [],
        currentCustomItems: [],
      } as TOriginalData,
      unbindShortcutKeys: null,
    };
  },

  props: {
    // URL path property
    novelId: String,
    // URL path property
    materialAttributeId: String,
    // URL path property
    materialId: String,
  },

  created() {
    const { initialize } = this;
    initialize();
    window.addEventListener("beforeunload", this.beforeUnload);

    // 保存ボタンのショートカットキーの有効化と解除関数の取得
    this.unbindShortcutKeys = handleShortcutKeys([
      { trigger: TriggerKey.Meta, keys: ["s"], callback: this.onSaveButtonClick },
      { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.onSaveButtonClick },
    ]);
  },

  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },

  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },

  computed: {
    attributes() {
      const { novelId, $store } = this;
      const { getters } = $store;
      return getters["materialModule/attributesFromNovelId"](novelId, true) as MaterialAttribute[];
    },
    templates() {
      const { getters } = this.$store;
      return getters["materialModule/templates"] as MaterialInputTemplate[];
    },
    deleteCount() {
      const { checkedItems } = this;
      if (checkedItems.length > 0) {
        return ` (${checkedItems.length})`;
      }

      return "";
    },
    isCreate() {
      const { currentNovelMaterial } = this;

      return currentNovelMaterial === null;
    },
    isActiveSaveButton() {
      const { currentBasicItems } = this;
      if (currentBasicItems.length < 1) {
        return false;
      }

      const firstItem = currentBasicItems[0];
      if (!firstItem.value) {
        return false;
      }

      return true;
    },
    isActiveDeleteButton() {
      const { materialId, checkedItems, $store } = this;
      const { getters } = $store;

      if (checkedItems.length > 0) {
        return true;
      }

      const material = getters["materialModule/material"](materialId);

      if (material) {
        return true;
      }

      return false;
    },
    isFormChanged() {
      const currentData = {
        currentAttribute: this.currentAttribute,
        currentTemplate: this.currentTemplate,
        currentLayout: this.currentLayout,
        currentBasicItems: this.currentBasicItems,
        currentCustomItems: this.currentCustomItems,
      };

      return !_.isEqual(currentData, this.originalData);
    },
  },

  methods: {
    // init
    async initialize() {
      const { materialAttributeId, materialId, $store, $route, $router } = this;
      if ($route.query.folderId) {
        this.folderId = $route.query.folderId as string;
      }

      const { getters } = $store;
      const attribute = getters["materialModule/attribute"](materialAttributeId) as MaterialAttribute;
      this.currentAttribute = attribute ?? null;

      const material = getters["materialModule/material"](materialId) as NovelMaterial;
      this.currentNovelMaterial = material ?? null;

      const pushAnnounce = !(await isBilling($store));
      if (!material && pushAnnounce) {
        $router.push({ name: "subscriptionAnnounce", query: { from: "createNovelMaterial" } });
        return;
      }

      if (!material) {
        // フォームの値の変更を検知する為に初期値を保存
        this.initializeOriginalData();
        return;
      }

      let sliceCount = 3;
      if (material.layout === LayoutEnum.DICTIONARY) {
        sliceCount = 2;
      }
      this.currentLayout = material.layout;
      this.currentBasicItems = material.items.slice(0, sliceCount);
      this.currentCustomItems = material.items.slice(sliceCount, material.items.length);

      // フォームの値の変更を検知する為に初期値を保存
      this.initializeOriginalData();
    },

    // update value (or execute action)

    setTemplate(template) {
      const preTemplate = this.currentTemplate;
      const { currentLayout, currentBasicItems, currentCustomItems, setBasicItems } = this;
      const positiveCallback = async () => {
        this.currentTemplate = template.id;
        this.currentLayout = template.layout;
        setBasicItems(template.layout);
        this.currentCustomItems = template.items;
      };

      if (currentLayout || currentBasicItems.length > 0 || currentCustomItems.length > 0) {
        const confirmDialog = new Dialog(SimpleDialog);
        const data: SimpleDialogProps = {
          title: "テンプレートを適用しますか？",
          content: "作業中の内容は上書きされます。本当に適用しますか？\n※元に戻すことはできません。",
          positive: "適用する",
          positiveCallback,
          negativeCallback: () => {
            this.currentTemplate = preTemplate;
          },
        };
        confirmDialog.show(data);
        return;
      }

      positiveCallback();
    },
    setBasicItems(layout: string) {
      const { currentBasicItems } = this;
      const preItems = currentBasicItems;
      const items = [initSinglelineItem("名称"), initSinglelineItem("ふりがな", false)];
      switch (layout as LayoutEnum) {
        case LayoutEnum.RESUME:
          items.push(initSquareItem());
          break;
        case LayoutEnum.ENLARGEMENT:
          items.push(initLandscapeItem());
          break;
        case LayoutEnum.DICTIONARY:
        default:
          break;
      }

      for (let index = 0; index < preItems.length; index += 1) {
        const item = preItems[index];
        if (items.length > index) {
          items[index] = {
            ...items[index],
            value: item.value,
          };
        }
      }

      this.currentBasicItems = items;
    },
    addCustomItem(type) {
      const { currentCustomItems, showErrorDialog } = this;

      if (currentCustomItems.length > 29) {
        showErrorDialog("追加できる項目は30個までです。");
        return;
      }

      let item;
      switch (type) {
        case MaterialItemEnum.IMAGE_SQUARE:
          item = initSquareItem();
          break;
        case MaterialItemEnum.IMAGE_PORTRATE:
          item = initPortrateItem();
          break;
        case MaterialItemEnum.IMAGE_LANDSCAPE:
          item = initLandscapeItem();
          break;
        case MaterialItemEnum.TEXT_MULTILINE:
        default:
          item = initMultilineItem();
          break;
      }

      currentCustomItems.push(item);
    },
    updateCheckedItems(item) {
      const { checkedItems } = this;
      if (checkedItems.some((checkedItem) => checkedItem.id === item.id)) {
        this.checkedItems = checkedItems.filter((checkedItem) => checkedItem.id !== item.id);
        return;
      }

      this.checkedItems.push(item);
    },
    updateFolder(folder, materialId, includes) {
      const result = { ...folder };
      // 追加
      if (includes) {
        // もともと入っていた場合はそのまま
        if (folder.materialOrder.includes(materialId)) {
          return result;
        }

        // ない場合は追加
        result.materialOrder = result.materialOrder.concat(materialId);
        return result;
      }

      // 削除
      if (!folder.materialOrder.includes(materialId)) {
        return result;
      }

      result.materialOrder = result.materialOrder.filter((id) => id !== materialId);

      return result;
    },
    async createNovelMaterial() {
      const {
        $store,
        currentAttribute,
        currentLayout: layout,
        currentBasicItems,
        currentCustomItems,
        materialId: id,
        novelId,
        updateMaterialFolder,
        showErrorDialog,
      } = this;

      if (!currentAttribute || !layout) {
        showErrorDialog(
          `資料種別またはレイアウトが正しく選択されていません。\nAttribute:${currentAttribute}\nLayout:${layout}`
        );
        return;
      }

      const { dispatch } = $store;
      const material: NovelMaterial = {
        id,
        novelId,
        attributeId: currentAttribute.id,
        layout,
        items: currentBasicItems.concat(currentCustomItems),
      };

      try {
        await dispatch("materialModule/createNovelMaterial", material);
        updateMaterialFolder(false);

        const { $router, materialAttributeId, materialId } = this;
        $router.push({
          name: "materials",
          params: {
            novelId,
            materialAttributeId,
            materialId,
          },
        });
        this.$notify({
          title: "作成しました",
          type: "success",
        });
      } finally {
        this.isProgress = false;
      }
    },
    async updateNovelMaterial() {
      const {
        $store,
        currentAttribute,
        currentLayout: layout,
        currentBasicItems,
        currentCustomItems,
        materialId: id,
        novelId,
        showErrorDialog,
      } = this;

      if (!currentAttribute || !layout) {
        showErrorDialog(
          `資料種別またはレイアウトが正しく選択されていません。\nAttribute:${currentAttribute}\nLayout:${layout}`
        );
        return;
      }

      const { dispatch } = $store;
      const material: NovelMaterial = {
        id,
        novelId,
        attributeId: currentAttribute.id,
        layout,
        items: currentBasicItems.concat(currentCustomItems),
      };

      try {
        await dispatch("materialModule/updateNovelMaterial", material);
        const { $router, materialAttributeId, materialId } = this;
        $router.push({
          name: "materials",
          params: {
            novelId,
            materialAttributeId,
            materialId,
          },
        });
        this.$notify({
          title: "保存しました",
          type: "success",
        });
      } finally {
        this.isProgress = false;
      }
    },
    async updateMaterialFolder(isDelete = false) {
      const { folderId, $store, materialAttributeId, materialId, isCreate, updateFolder } = this;
      const { getters, dispatch } = $store;

      // 削除する場合はフォルダも更新する
      if (isDelete) {
        const folders = getters["materialModule/foldersFromAttribute"](materialAttributeId) as MaterialFolder[];
        const newFolders: MaterialFolder[] = [];
        for (let index = 0; index < folders.length; index += 1) {
          const folder = folders[index];
          newFolders.push(updateFolder(folder, materialId, [folderId].includes(folder.id)));
        }
        await dispatch("materialModule/updateMaterialFolder", newFolders);
        return;
      }

      if (!folderId) {
        return;
      }

      const folder = getters["materialModule/folder"](folderId) as MaterialFolder;

      if (!folder) {
        return;
      }

      if (isCreate) {
        dispatch("materialModule/updateMaterialFolder", [
          {
            ...folder,
            materialOrder: folder.materialOrder.concat(materialId),
          },
        ]);
      }
    },
    deleteNovelMaterial() {
      const { $store, novelId, materialAttributeId, materialId, $router, updateMaterialFolder } = this;
      const confirmDialog = new Dialog(SimpleDialog);
      const positiveCallback = async () => {
        this.isProgress = true;
        const { dispatch } = $store;
        await dispatch("materialModule/deleteNovelMaterial", [materialId]);
        updateMaterialFolder(true);
        $router.push({ name: "materials", params: { novelId, materialAttributeId } });
        this.isProgress = false;
      };
      const data: SimpleDialogProps = {
        title: "資料を削除しますか？",
        content: "本当に資料を削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback,
      };
      confirmDialog.show(data);
    },

    // event handle

    onSelectTemplate(event) {
      const { target } = event;
      event.preventDefault();
      if (target instanceof HTMLSelectElement) {
        const { $store } = this;
        const { getters } = $store;
        const templateId = target.value;
        const template = getters["materialModule/template"](templateId);

        if (!template) {
          return;
        }

        const { setTemplate } = this;
        setTemplate(template);
      }
    },
    onSelectLayout(event) {
      const { target } = event;
      if (target instanceof HTMLSelectElement) {
        const { setBasicItems } = this;
        this.currentLayout = target.value as LayoutEnum;
        setBasicItems(target.value);
      }
    },
    onUpdateItem(item) {
      const { currentBasicItems, currentCustomItems } = this;

      this.currentBasicItems = currentBasicItems.map((currentItem) => {
        if (currentItem.id === item.id) return item;
        return currentItem;
      });

      this.currentCustomItems = currentCustomItems.map((currentItem) => {
        if (currentItem.id === item.id) return item;
        return currentItem;
      });
    },
    onReorder(items: MaterialItem[]) {
      this.currentCustomItems = items;
    },
    onAddTextClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.TEXT_MULTILINE);
    },
    onAddSquareClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.IMAGE_SQUARE);
    },
    onAddPortraitClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.IMAGE_PORTRATE);
    },
    onAddLandscapeClick() {
      const { addCustomItem } = this;
      addCustomItem(MaterialItemEnum.IMAGE_LANDSCAPE);
    },
    onSaveButtonClick() {
      const { isActiveSaveButton } = this;
      if (!isActiveSaveButton) {
        return;
      }

      this.isProgress = true;

      const { $store, materialId, createNovelMaterial, updateNovelMaterial } = this;
      const { getters } = $store;
      const material = getters["materialModule/material"](materialId);
      if (material) {
        updateNovelMaterial();
        return;
      }

      createNovelMaterial();
    },
    onDeleteButtonClick() {
      const { currentCustomItems, checkedItems, isActiveDeleteButton } = this;

      if (!isActiveDeleteButton) {
        return;
      }

      if (checkedItems.length > 0) {
        this.currentCustomItems = currentCustomItems.filter(
          (item) => !checkedItems.some((checkedItem) => checkedItem.id === item.id)
        );
        this.checkedItems = [];
        return;
      }

      const { deleteNovelMaterial } = this;
      deleteNovelMaterial();
    },
    onPreviewButtonClick() {
      const {
        materialId: id,
        novelId,
        currentAttribute,
        currentLayout: layout,
        currentBasicItems,
        currentCustomItems,
      } = this;
      const { isActiveSaveButton, showErrorDialog } = this;

      if (!isActiveSaveButton || !layout || !currentAttribute) {
        showErrorDialog("入力が足りません。");
        return;
      }

      const confirmDialog = new Dialog(MaterialPreviewDialog);
      const material: NovelMaterial = {
        id,
        novelId,
        attributeId: currentAttribute.id,
        layout,
        items: currentBasicItems.concat(currentCustomItems),
      };

      const params: MaterialPreviewDialogProps = {
        title: "プレビュー",
        material,
      };

      confirmDialog.show(params);
    },
    onCancelClick() {
      const { $router } = this;
      $router.back();
    },

    // util

    showErrorDialog(message: string) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },
    showLeaveConfirmDialog(next) {
      const confirmDialog = new Dialog(SimpleDialog);
      const options: SimpleDialogProps = {
        title: "資料を編集中です。",
        content: "保存していないデータが存在します。\nこのまま画面遷移しますか？\n※編集前のデータに戻ります。",
        negative: "キャンセル",
        positive: "遷移する",
        positiveCallback: () => {
          next();
        },
      };

      confirmDialog.show(options);
    },
    beforeUnload(event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "編集中です。";
    },
    initializeOriginalData() {
      this.originalData = {
        currentAttribute: this.currentAttribute,
        currentTemplate: this.currentTemplate,
        currentLayout: this.currentLayout,
        currentBasicItems: [...this.currentBasicItems],
        currentCustomItems: [...this.currentCustomItems],
      };
    },
  },

  // route event

  beforeRouteLeave(to, from, next) {
    const { isProgress, isFormChanged, showLeaveConfirmDialog } = this;

    if (isProgress || !isFormChanged) {
      next();
      return;
    }

    showLeaveConfirmDialog(next);
  },
});
