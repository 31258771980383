var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "list-tile",
    {
      staticClass: "tile",
      style: {
        paddingRight: _vm.isBasic ? "24px" : "16px",
        paddingLeft: _vm.isBasic ? "24px" : "16px",
      },
      attrs: { height: "auto" },
    },
    [
      !_vm.isBasic
        ? _c(
            "div",
            {
              staticClass: "leading",
              attrs: { slot: "leading" },
              slot: "leading",
            },
            [
              _vm.isCheck
                ? _c("checkbox-marked-outline-icon", {
                    staticClass: "mr-12 center pointer",
                    attrs: { size: 20 },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onCheck.apply(null, arguments)
                      },
                    },
                  })
                : _c("checkbox-blank-outline-icon", {
                    staticClass: "mr-12 center pointer",
                    attrs: { size: 20 },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onCheck.apply(null, arguments)
                      },
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm.inputType.includes("text")
        ? _c(
            "div",
            {
              staticClass: "content",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _vm.inputType.includes("singleline")
                ? _c("div", [
                    _c("span", { attrs: { for: "layout" } }, [
                      _vm._v(_vm._s(_vm.singlelineLabel(_vm.valueLimit))),
                      _vm.isRequired
                        ? _c("span", { staticClass: "require" }, [_vm._v("＊")])
                        : _vm._e(),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value,
                          expression: "value",
                        },
                      ],
                      staticClass: "single",
                      attrs: {
                        type: "text",
                        maxlength: _vm.valueLimit,
                        placeholder: _vm.singlelinePlaceholder,
                      },
                      domProps: { value: _vm.value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.value = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.inputType.includes("multiline")
                ? _c("div", [
                    _c("span", { attrs: { for: "layout" } }, [
                      _vm._v(_vm._s(_vm.singlelineLabel(_vm.titleLimit))),
                      _vm.isRequired
                        ? _c("span", { staticClass: "require" }, [_vm._v("＊")])
                        : _vm._e(),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title",
                        },
                      ],
                      staticClass: "single",
                      attrs: {
                        type: "text",
                        maxlength: _vm.titleLimit,
                        placeholder: _vm.singlelinePlaceholder,
                      },
                      domProps: { value: _vm.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.title = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("span", { attrs: { for: "layout" } }, [
                        _vm._v(_vm._s(_vm.multilineLabel)),
                      ]),
                      _c("div", { staticClass: "multiple" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.value,
                              expression: "value",
                            },
                          ],
                          attrs: {
                            type: "text",
                            maxlength: _vm.valueLimit,
                            placeholder: _vm.multilinePlaceholder,
                            rows: "5",
                          },
                          domProps: { value: _vm.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.value = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.inputType.includes("image")
        ? _c(
            "div",
            {
              staticClass: "content",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              !_vm.isImageOnly
                ? _c("div", [
                    _c("span", { attrs: { for: "layout" } }, [
                      _vm._v(_vm._s(_vm.singlelineLabel(_vm.titleLimit))),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title",
                        },
                      ],
                      staticClass: "single",
                      attrs: {
                        type: "text",
                        maxlength: _vm.titleLimit,
                        placeholder: _vm.singlelinePlaceholder,
                      },
                      domProps: { value: _vm.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.title = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.isImageOnly
                ? _c("span", { attrs: { for: "layout" } }, [_vm._v("画像")])
                : _vm._e(),
              !_vm.image
                ? _c(
                    "div",
                    { staticClass: "image-default", class: `${_vm.inputType}` },
                    [
                      _c("img", {
                        staticClass: "no-image",
                        attrs: {
                          src: require("@/assets/img/icon/image_default.png"),
                        },
                      }),
                      _c("span", [_vm._v("ここに画像が入ります")]),
                    ]
                  )
                : _vm._e(),
              _vm.image && !_vm.isPreset
                ? _c(
                    "div",
                    { staticClass: "image-default", class: `${_vm.inputType}` },
                    [
                      _c("load-image", {
                        staticClass: "preview",
                        attrs: { path: _vm.image },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.image && _vm.isPreset
                ? _c(
                    "div",
                    { staticClass: "image-default", class: `${_vm.inputType}` },
                    [
                      _c("load-image", {
                        staticClass: "preview preset",
                        attrs: { alt: "", path: _vm.image },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-border",
                    on: { click: _vm.onPresetButtonClick },
                  },
                  [_vm._v("プリセットから選択")]
                ),
                _c(
                  "label",
                  {
                    staticClass: "btn btn-border",
                    attrs: { for: _vm.item.id },
                  },
                  [_vm._v("ローカルから選択")]
                ),
                _c("input", {
                  style: { display: "none" },
                  attrs: { id: _vm.item.id, type: "file", accept: "image/*" },
                  on: {
                    input: _vm.selectedImageFile,
                    click: (e) => (e.target.value = ""),
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-border",
                    on: { click: _vm.onClearButtonClick },
                  },
                  [_vm._v("クリア")]
                ),
              ]),
              !_vm.isImageOnly
                ? _c("div", { staticClass: "mt-12" }, [
                    _c("span", { attrs: { for: "layout" } }, [
                      _vm._v(_vm._s(_vm.multilineLabel)),
                    ]),
                    _c("div", { staticClass: "multiple" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value,
                            expression: "value",
                          },
                        ],
                        attrs: {
                          type: "text",
                          maxlength: _vm.valueLimit,
                          placeholder: _vm.multilinePlaceholder,
                          rows: "5",
                        },
                        domProps: { value: _vm.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.value = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      !_vm.isBasic
        ? _c(
            "div",
            {
              staticClass: "trailing",
              attrs: { slot: "trailing" },
              slot: "trailing",
            },
            [
              _c("img", {
                staticClass: "drag-icon",
                attrs: { src: require("@/assets/img/icon/drag.png") },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }